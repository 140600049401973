import * as React from "react"
import Logo from "../images/LAB_bird_logo_dark.svg"
import favicon from "../images/favicon-96x96.png"
import "animate.css"
import { Helmet } from "react-helmet"
// import HeadBG1 from "../images/head_bg_1.jpg"
import LABLogo from "../images/logo_txt_b.svg"
// styles
const pageStyles = {
  color: "#ffffff",
  padding: "0px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const IndexPage = () => {
  setTimeout(() => {
    window.location = "https://www.liveactivitybase.com/main"
  }, 5000)
  return (
    <main style={pageStyles}>
      <Helmet
        title="LAB | Live Activity Base"
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
        meta={[
          {
            name: "viewport",
            content:
              "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
          },
        ]}
        defer={false}
      />
      <div className="logo animate__animated animate__backInDown ">
        <Logo />
      </div>
      <div className="logo logo-text animate__animated animate__slideInLeft animate__delay-1s">
        <LABLogo />
      </div>
    </main>
  )
}

export default IndexPage
